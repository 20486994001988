import { Table } from 'antd';
import React from 'react'

const AdvancedStdDemoReportSummaryTable = ({table_list}) => {
  const columns = [
    {
      title: "Student Name",
     render: (text, record) => {
        return (
          <>
            {record?.student?.first_name}{" "}{record?.student?.middle_name == "null"? "" : record?.student?.middle_name }{" "}{record?.student?.last_name}
          </>
        );
      },
    },
    {
      title: "Student Id",
      render: (text, record) => {
        return (
          <>
            {record?.student?.student_id}
          </>
        );
      },
    },
    {
      title: "Age",
      render: (text, record) => {
        return (
          <>
           <span style={{textTransform:"capitalize"}}>{calculateAge(record?.student?.birth_date)}</span>
          </>
        );
      },
    },
    {
        title: "Gender",
        render: (text, record) => {
          return (
            <>
             <span style={{textTransform:"capitalize"}}>{record?.student?.gender}</span>
            </>
          );
        },
      },
      {
        title: "Ethnicity",
        render: (text, record) => {
          return (
            <>
             {record?.student?.ethnicity ? record?.student?.ethnicity : "-"}
            </>
          );
        },
      },
      {
        title: "Program",
        render: (text, record) => {
          return (
            <>
             {record?.student?.ethnicity ? record?.student?.ethnicity : "-"}
            </>
          );
        },
      },
      {
        title: "Admission Status",
        dataIndex: "gpa",
        key: "gpa",
      },
      {
        title: "Student Category",
        dataIndex: "Refund Status",
        key: "Refund Status",
      },
      {
        title: "Home State",
        dataIndex: "Refund Status",
        key: "Refund Status",
      },
      {
        title: "Home Country",
        dataIndex: "Refund Status",
        key: "Refund Status",
      },
      {
        title: "Citizenship",
        dataIndex: "Refund Status",
        key: "Refund Status",
      },
  ];
  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}
  return (
    <>
<Table columns={columns} pagination={true} dataSource={table_list}/>
    </>
  )
}

export default AdvancedStdDemoReportSummaryTable ;