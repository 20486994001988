import React, { useEffect, useState } from "react";
import AdvancedAcademicReportSummaryTable from "./advancedAcademicPerformanceReportSummaryTable";
import { Button, Select, Slider } from "antd";
import { useNavigate } from "react-router-dom";
import { ADVANCE_ACADEMIC_PERFORMANCE_REPORT, CALENDARS_LIST, PROGRAMS_LIST } from "../../../../apis/apis";
import SisLoader from "../../../../widgets/loader";
import AdvancedAcademicPerformanceReportSummaryTable from "./advancedAcademicPerformanceReportSummaryTable";

const AdvancedAcademicPerformanceReportVisualSection = () => {
  const [loader, set_loader] = useState(false);
  const [academic_year, set_academic_year] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [gender, set_gender] = useState("");
  const [identify, set_identify] = useState("");
  const [age, set_age] = useState("");
  const [errors, set_errors] = useState([]);
  const [academic_year_list, set_academic_year_list] = useState([]);
  const [semester, set_semester] = useState("");
  const [gpa_range, set_gpa_range] = useState("");
  const [sem_list, set_sem_list] = useState([]);
  const [programme_list, set_programme_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0);
  const navigate = useNavigate();

  const LIST_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_academic_year_list(API_RESPONSE?.data?.calendars);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  const calender_change_function = (value) => {
    set_semester("");
    set_errors("");
    const index = academic_year_list.findIndex((item) => item.id === value);
    set_academic_year(value);
    set_calender_index(index);
    set_sem_list(academic_year_list[index].semesters);
  };

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE.data.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
    PROGRAMS_LIST_API();
  }, [academic_year]);


  const GENERATE_ADVANCE_ACADEMIC_PERFORMANCE_REPORT = async()=>{
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calender_id", academic_year);
    FORM_DATA.append("semester_id", semester);
    FORM_DATA.append("student_level", entering_classification);
    FORM_DATA.append("program_id", programme_intended);
    FORM_DATA.append("gpa_range", gpa_range);
    FORM_DATA.append("age", age);
    FORM_DATA.append("gender", gender);
    FORM_DATA.append("identify", identify);
    const API_RESPONSE = await ADVANCE_ACADEMIC_PERFORMANCE_REPORT(FORM_DATA);
    if(API_RESPONSE?.data?.status)
    {
      set_table_list(API_RESPONSE?.data?.report);
      set_loader(false);
    }
    else{
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  }




  return (
    <>
    {loader ? <SisLoader/> :
      <>
      <div className="theme-content-left-head">
        <h2>Academic Performance Reports</h2>
        <br />
        <p>
          Track student academic performance, including grades, GPA, and
          academic standing.
        </p>
      </div>
      <br />
      <hr />
      <br />
      <h3 style={{ fontSize: "18px", fontWeight: "600", color: "#4b4b4b" }}>
        Filters
      </h3>
      <br />
      <div className="row">
        <div className="col-3">
          <div className="input-box" style={{ width: "100%" }}>
            <label>
              Academic Year<i style={{ color: "red" }}>*</i>
            </label>
            <br />
            <Select
              placeholder="--Select Academic Year--"
              style={{ width: "100%", height: "40px" }}
              onChange={calender_change_function}
              value={academic_year ? parseInt(academic_year) : academic_year}
              options={academic_year_list.map((item) => ({
                value: item.id,
                calender_id: item.id,
                label: item.title,
              }))}
            />
            {errors?.calender_id && (
              <>
                <span style={{ color: "red" }}>
                The Academic Year field is required.
                </span>
              </>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="input-box" style={{ width: "100%" }}>
            <label>
              Semester<i style={{ color: "red" }}>*</i>
            </label>
            <br />

            <Select
              placeholder="--Select Semester--"
              style={{ width: "100%", height: "40px" }}
              disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
              onChange={(value) => {
                set_semester(value);
              }}
              value={semester}
              options={sem_list.map((item) => ({
                value: item?.id,
                label: item?.title,
              }))}
            />

            {errors?.semester_id && (
              <>
                <span style={{ color: "red" }}>
                  The semester field is required.
                </span>
              </>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="input-box" style={{ width: "100%" }}>
            <label>
              Entering Classification<i style={{ color: "red" }}>*</i>
            </label>
            <br />
            <Select
              style={{ width: "100%", height: "40px" }}
              onChange={(value) => {
                set_entering_classification(value);
              }}
              value={entering_classification}
              placeholder="Select Student Level"
              required
            >
              <Select.Option value="undergraduate">Undergraduate</Select.Option>
              <Select.Option value="graduate">Graduate</Select.Option>
              <Select.Option value="doctorate">Doctorate</Select.Option>
              <Select.Option value="cohort">Cohort</Select.Option>
            </Select>
            {errors?.student_level && (
              <>
                <span style={{ color: "red" }}>
                The Student Level field is required.
                </span>
              </>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="input-box">
            <label>
              Program / Major<i style={{ color: "red" }}>*</i>
            </label>
            <Select
            value={programme_intended}
              placeholder="--Select programme--"
              style={{ width: "100%", height: "40px" }}
              onChange={(value) => set_programme_intended(value)}
            >
              {programme_list.map((item) => {
                if (item.status === 1 && item.soft_delete === 0) {
                  return (
                    <>
                      <Select.Option value={item.id}>
                        {item.title}
                      </Select.Option>
                    </>
                  );
                }
              })}
            </Select>
            {errors?.program_id && (
              <>
                <span style={{ color: "red" }}>
                The Program field is required.
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-3">
          <div className="input-box" style={{ width: "100%" }}>
            <label>
              GPA Range
            </label>
            <br />
            <Slider
              min={0}
              max={5}
              onChange={(value) => {
                if (Number.isNaN(value)) {
                  return;
                }
                set_gpa_range(value);
              }}
              value={typeof gpa_range === "number" ? gpa_range : 0}
              step={0.1}
            />
            {errors?.gpa_range && (
              <>
                <span style={{ color: "red" }}>{errors?.gpa_range[0]}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <h3 style={{ fontSize: "15px", fontWeight: "600", color: "#4b4b4b" }}>
        Student Demographic
      </h3>
      <div className="row">
        <div className="col-3">
          <div className="input-box" style={{ width: "100%" }}>
            <label>
              Age
            </label>
            <br />
            <Slider
              min={15}
              max={40}
              onChange={(value) => {
                if (Number.isNaN(value)) {
                  return;
                }
                set_age(value);
              }}
              value={typeof age === "number" ? age : 0}
              step={1}
            />
            {errors?.age && (
              <>
                <span style={{ color: "red" }}>{errors?.age[0]}</span>
              </>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="input-box">
            <label>
              Gender
            </label>
            <br></br>
            <Select
              value={gender}
              style={{ width: "100%", height: "40px" }}
              onChange={(value) => set_gender(value)}
              placeholder="--Select Gender--"
            >
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
              <Select.Option value="others">Others</Select.Option>
            </Select>
            {errors?.gender && (
              <>
                <span style={{ color: "red" }}>{errors?.gender[0]}</span>
              </>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="input-box">
            <label>Ethnicity</label>
            <Select
              disabled={localStorage.getItem("payment_status") == 1}
              value={identify}
              style={{ width: "100%", height: "40px" }}
              onChange={(value) => set_identify(value)}
              placeholder="--identify yourself--"
            >
              <Select.Option value="american indian or native alaskan">
                American Indian or Native Alaskan
              </Select.Option>
              <Select.Option value="asian or pacific islander">
                Asian or Pacific Islander
              </Select.Option>
              <Select.Option value="african american">
                African American
              </Select.Option>
              <Select.Option value="hispanic">Hispanic</Select.Option>
              <Select.Option value="white">White</Select.Option>
            </Select>
            {errors?.identify && (
              <>
                <span style={{ color: "red" }}>{errors?.identify[0]}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <Button
      onClick={GENERATE_ADVANCE_ACADEMIC_PERFORMANCE_REPORT}
        style={{
          backgroundColor: "#4b4b4b",
          color: "#ffffff",
          width: "156px",
          height: "40px",
        }}
        type="primary"
      >
        Generate Report
      </Button>
      <br />
      <br />
      {table_list?.length > 0 && 
      <AdvancedAcademicPerformanceReportSummaryTable table_list={table_list} />
      }
      </>
    }
   </>
  );
};

export default AdvancedAcademicPerformanceReportVisualSection;
