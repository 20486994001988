

import React from 'react'

const MultipleCoursePrint = (data) => {
  return (
    <>
    {data?.student_by_semester_courses_detail?.length > 0 &&
    (data?.student_by_semester_courses_detail?.map((item)=>(
    <>
    <ul>
    <li>
    <p style={{margin:" 0 0 10px 0"}}>
    <span><b>Course :-</b> {item?.course?.title}{" "}{"("}{item?.course?.code}{")"}<br/></span>
    <span><b>Given Credit :-</b> {item?.course_semester_registration?.given_credits}</span>
    </p>
    </li>
    </ul>
    </>
    )))

    }
    </>
  )
}

export default MultipleCoursePrint;