import React from 'react'
import { Table } from 'antd';
import MultipleCoursePrint from '../../MultipleCoursePrint/multipleCoursePrint';


const AdvancedEnrollAppTrackReportSummaryTable = ({table_list}) => {
    const columns = [
        {
          title: "Student Name",
         render: (text, record) => {
            return (
              <>
                {record?.student?.first_name}{" "}{record?.student?.middle_name == "null"? "" : record?.student?.middle_name }{" "}{record?.student?.last_name}
              </>
            );
          },
        },
        {
          title: "Application Id",
          render: (text, record) => {
            return (
              <>
                {record?.student?.student_id}
              </>
            );
          },
        },
        {
          title: "Email",
          render: (text, record) => {
            return (
              <>
                {record?.student?.primary_email}
              </>
            );
          },
        },
        {
          title: "Age",
          render: (text, record) => {
            return (
              <>
               <span style={{textTransform:"capitalize"}}>{calculateAge(record?.student?.birth_date)}</span>
              </>
            );
          },
        },
        {
            title: "Gender",
            render: (text, record) => {
              return (
                <>
                 <span style={{textTransform:"capitalize"}}>{record?.student?.gender}</span>
                </>
              );
            },
          },
          {
            title: "Ethnicity",
            render: (text, record) => {
              return (
                <>
                 {record?.student?.ethnicity ? record?.student?.ethnicity : "-"}
                </>
              );
            },
          },
          {
            title: "Program / Intended Degree Type",
            dataIndex: "gpa",
            key: "gpa",
          },
          {
            title: "Application Status",
            dataIndex: "gpa",
            key: "gpa",
          },
          {
            title: "Admission Type",
            dataIndex: "cgpa",
            key: "cgpa",
          },
          {
            title: "Admission Decision",
            dataIndex: "cgpa",
            key: "cgpa",
          },
          {
            title: "Enrollment Contract",
            dataIndex: "cgpa",
            key: "cgpa",
          },
      ];
      function calculateAge(dob) {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
    
        // Adjust if the birthday hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }
  return (
    <>
    <Table columns={columns} pagination={true} dataSource={table_list}/>
    </>
  )
}

export default AdvancedEnrollAppTrackReportSummaryTable