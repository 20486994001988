import React from 'react'
import { Table } from 'antd';
import MultipleCoursePrint from '../../MultipleCoursePrint/multipleCoursePrint';

const AdvancedFinancialReportSummaryTable = ({table_list}) => {
    const columns = [
        {
          title: "Student Name",
         render: (text, record) => {
            return (
              <>
                {record?.student?.first_name}{" "}{record?.student?.middle_name == "null"? "" : record?.student?.middle_name }{" "}{record?.student?.last_name}
              </>
            );
          },
        },
        {
          title: "Student Id",
          render: (text, record) => {
            return (
              <>
                {record?.student?.student_id}
              </>
            );
          },
        },
        {
          title: "Email",
          render: (text, record) => {
            return (
              <>
                {record?.student?.primary_email}
              </>
            );
          },
        },
        {
            title: "Account Balance",
            render: (text, record) => {
              return (
                <>
                 <span style={{textTransform:"capitalize"}}>{record?.student?.gender}</span>
                </>
              );
            },
          },
          {
            title: "Fees Type",
            render: (text, record) => {
              return (
                <>
                 {record?.student?.ethnicity ? record?.student?.ethnicity : "-"}
                </>
              );
            },
          },
          {
            title: "Payments Plans",
            dataIndex: "gpa",
            key: "gpa",
          },
          {
            title: "Refund Status",
            dataIndex: "Refund Status",
            key: "Refund Status",
          },
          
      ];
     
  return (
   <>
 <Table columns={columns} pagination={true} dataSource={table_list}/>
   </>
  )
}

export default AdvancedFinancialReportSummaryTable;