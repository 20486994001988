/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Checkbox,
  Input,
  Select,
  Popover,
  TimePicker,
  Button,
  notification,
} from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import {
  COURSES_LIST,
  SEARCH_COURSE,
  SEMESTERS_COURSES_UPDATE,
  SEMESTERS_COURSES_DELETE,
} from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  UserOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const TimingColumn = (props) => {
  const { calendar_id, sem_id, item, set_r_loader } = props;
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [open, set_open] = useState(false);

  const [errors, set_errors] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [table_list_search, set_table_list_search] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}

  const [selected_days, set_selected_days] = useState([
    {
      key: 1,
      value: 'Monday',
      start_time: null,
      end_time: null,
    },
    {
      key: 2,
      value: 'Tuesday',
      start_time: null,
      end_time: null,
    },
    {
      key: 3,
      value: 'Wednesday',
      start_time: null,
      end_time: null,
    },
    {
      key: 4,
      value: 'Thursday',
      start_time: null,
      end_time: null,
    },
    {
      key: 5,
      value: 'Friday',
      start_time: null,
      end_time: null,
    },
    {
      key: 6,
      value: 'Saturday',
      start_time: null,
      end_time: null,
    },
    {
      key: 7,
      value: 'Sunday',
      start_time: null,
      end_time: null,
    },
  ]);
  const [start_time, set_start_time] = useState("");
  const [end_time, set_end_time] = useState("");

  const onChangeTime = (time, timeString, type, key) => {
    var arrayList = selected_days;
    var index = arrayList.findIndex(obj => obj['key'] === key);
    if (type === "start_time") {
      if (index !== -1) {
        arrayList[index] = {key:key, value: arrayList[index].value, end_time: arrayList[index].end_time, start_time: timeString};
        set_selected_days(arrayList);
    }
    } else {
      if (index !== -1) {
        arrayList[index] = {key:key, value: arrayList[index].value, start_time: arrayList[index].start_time,  end_time: timeString};
        set_selected_days(arrayList);
    }
    }
  };
  const onChangeDays = (checkedValues) => {
    set_selected_days(checkedValues);
  };
  const UPDATE_API = async () => {
    set_loader(true);
    set_open(false);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", atob(calendar_id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("course_id", item.course_id);
    FORM_DATA.append("faculty", item.faculty == null ? "" : item.faculty);
    FORM_DATA.append("room_id", item.room_id == null ? "" : item.room_id);
    FORM_DATA.append('parent', item?.parent);
    FORM_DATA.append("timing",JSON.stringify(selected_days));
    // FORM_DATA.append('enrollment_students', item.enrollment_students);
    // FORM_DATA.append('scheduled', item.scheduled);
    // FORM_DATA.append('schedule_status', item.schedule_status);
    FORM_DATA.append("id", item.schedule_id);
    const API_RESPONSE = await SEMESTERS_COURSES_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_r_loader(true);
      set_open(false);
      set_loader(true);
      notification.open({
        message: 'Success!!',
        description: 'Class times have been successfully scheduled!',
        icon: <SmileOutlined style={{ color: 'green' }} />,
    });
      navigate("/semester-courses/" + calendar_id + "/" + sem_id);
    } else {
      set_errors(API_RESPONSE.data.errors);
    }
  };

  const set_sem_course = (value) => {
    set_loader(true);
    UPDATE_API();
  };
  
  // const handleOpenChange = (newOpen: boolean) => {
  //       set_open(false);
  //       set_loader(false);

  // };
  const handle_open = () => {
    if (item?.timing) {
      var json = JSON.parse(item?.timing);
      set_selected_days(json);
      set_open(true);
      set_loader(false);
    }
  };

  const refreshMo = () => {
    set_open(false);
    set_loader(true);
    navigate("/semester-courses/" + calendar_id + "/" + sem_id);
  };
  const handleOpenChange = (newOpen: boolean) => {
    if (open === true) {
      set_open(false);
      set_loader(true);
      navigate("/semester-courses/" + calendar_id + "/" + sem_id);
    }
  };
  useEffect(() => {
    if (item?.timing) {
      var json = JSON.parse(item?.timing);
      set_selected_days(json);
    }
    set_loader(false);
  }, []);
  console.log("loaded", selected_days)
  return (
    <>
      {loader ? (
        <LoadingOutlined />
      ) : (
        <>
          <Popover
            style={{ width: "340px" }}
            content={
              <>
                <div style={{ width: "350px" }}>
                  <div className="row">
                    <div className="col-4" style={{ width: "34.22%" }}>
                      <div className="week_day">
                        <b>Day</b>
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{ width: "25.22%", marginBottom: "-13px" }}
                    >
                      <div className="week_day">
                        <b>Start Time</b>
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{ width: "22.22%", marginBottom: "-13px" }}
                    >
                      <div className="week_day">
                        <b>End Time</b>
                      </div>
                    </div>
                  </div>
                  {selected_days?.map((item) => (
                    <>
                      <div className="row">
                        <div className="col-4" style={{ width: "27.22%" }}>
                          <label style={{ position: "relative", top: "13px" }}>
                            <span> {item.value}</span>
                          </label>
                        </div>
                        <div
                          className="col-4"
                          style={{ width: "25.22%", marginBottom: "-13px" }}
                        >
                          <TimePicker
                            defaultValue={
                              item.start_time && dayjs(item.start_time, "h:mm a")
                            }
                            onChange={(time, timeString) =>
                              onChangeTime(time, timeString, "start_time", item.key)
                            }
                            style={{
                              width: "110px",
                              margin: "8px 0px 15px 0px",
                            }}
                            use12Hours
                            format="h:mm a"
                          />
                        </div>
                        <div
                          className="col-4"
                          style={{ width: "22.22%", marginBottom: "-13px" }}
                        >
                          <TimePicker
                            defaultValue={item.end_time && dayjs(item.end_time, "h:mm a")}
                            onChange={(time, timeString) =>
                              onChangeTime(time, timeString, "end_time", item.key)
                            }
                            style={{
                              width: "110px",
                              margin: "8px 0px 15px 24px",
                            }}
                            use12Hours
                            format="h:mm a"
                          />
                        </div>
                      </div>
                    </>
                  ))}

                  {/* <div className="col-4">
                    <div className="week_day"><b>Start Time</b></div>
                    <TimePicker defaultValue={start_time && dayjs(start_time, 'h:mm a')} onChange={(time, timeString) => onChangeTime(time, timeString, 'start_time')} style={{width:"110px", margin:"8px 0px 15px 0px"}} use12Hours format="h:mm a" />
                  </div>
                  <div className="col-4">
                    <div className="week_day"><b>End Time</b></div>
                    <TimePicker defaultValue={end_time && dayjs(end_time, 'h:mm a')} onChange={(time, timeString) => onChangeTime(time, timeString, 'end_time')}  style={{width:"110px", margin:"8px 0px 15px 0px"}} use12Hours format="h:mm a" />
                  </div> */}
                </div>
                <div style={{ textAlign: "right", marginTop: "15px" }}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={refreshMo}
                    danger
                  >
                    Cancel
                  </Button>{" "}
                  <Button onClick={set_sem_course} type="primary" size="small">
                    Apply
                  </Button>
                </div>
              </>
            }
            title="Timing"
            open={open}
            trigger="click"
            onOpenChange={handleOpenChange}
          >
            <div className="row" style={{ width: "170px", paddingLeft:"5px" }} title="Title">
              <span>
                {item?.timing &&  <>
                    {selected_days?.findIndex(obj => obj['value'] !== null) !== -1 && selected_days.map((item) => (
                      <>
                      {item?.start_time && item?.end_time && <>
                        <b>{item?.value?.slice(0, 2).toUpperCase()}</b> {item?.start_time} - {item?.end_time}<br/>
                      </>}
                     
                      </>
                    ))}
                    
                  </>}
                  {(check_permissions('calendars_schedules', 'create') || check_permissions('calendars_schedules', 'update')) &&
                  <Button  onClick={() => set_open(true)} type="primary" size="small">Add Timing</Button>}
              </span>
            </div>
          </Popover>
        </>
      )}
    </>
  );
};

export default TimingColumn;
