import React, { useEffect, useState } from "react";
import "./layout.css";
import Sider from "antd/es/layout/Sider";
import user_icon from "./../images/user.png";
import { Button, Layout, Menu, Spin, theme } from "antd";
import {
  CalendarOutlined,
  DashboardOutlined,
  FormOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SafetyCertificateOutlined,
  SnippetsOutlined,
  UploadOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  LoginOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Content, Header } from "antd/es/layout/layout";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import logoTheme from "./../images/fav.png";
import { CHECK_TOKEN } from "../apis/apis";
const SisLayout = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selected_menu, set_selected_menu] = useState();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [login_check, set_login_check] = useState(false);
  const [loader, set_loader] = useState(true);
  const [name, set_name] = useState(true);
  const [role_name, set_role_name] = useState(true);
  // Permissions state manage 
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const [user_role, set_user_role] = useState('');
  const location_pathname = window.location.pathname;
  // Menu Items 
  const [menu_item, set_menu_item] = useState([{
    key: "1",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    onClick: function (e) {
      navigate("/")
    }
  }]);


  // Dropdown State 
  const [attendance_dropdown, set_attendance_dropdown] = useState(false);
  const [plan_dropdown, set_plan_dropdown] = useState(false);
  const [report_dropdown, set_report_dropdown] = useState(false);
  const [advanced_report_dropdown, set_advanced_report_dropdown] = useState(false);
  const [basic_report_dropdown, set_basic_report_dropdown] = useState(false);
  const CHECK_TOKEN_API = async () => {
    try {
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA?.token);
      FORM_DATA.append("user_type", "admin");
      const CHECK_TOKEN_RESPONSE = await CHECK_TOKEN(FORM_DATA);
      if (CHECK_TOKEN_RESPONSE?.data?.status) {
        localStorage.setItem('sis_user_data', JSON.stringify(CHECK_TOKEN_RESPONSE.data.data));
        // const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (CHECK_TOKEN_RESPONSE.data.data.role_permissions?.all_permissions || CHECK_TOKEN_RESPONSE.data.data.role === 2074) {
          set_all_permissions(true);
        }
        set_name(CHECK_TOKEN_RESPONSE.data.data.name);
        // console.log('localStorgeData', localStorgeData?.role_permissions.permissions)
        set_permissions(CHECK_TOKEN_RESPONSE?.data?.data?.role_permissions?.permissions);
        set_user_role(CHECK_TOKEN_RESPONSE?.data?.data?.role_permissions?.role)
        set_role_name(CHECK_TOKEN_RESPONSE?.data?.data?.role_permissions?.role_name)
        set_loader(false);

      } else {
        localStorage.clear();
        //     window.location = 'https://dev-students.icore.uk/';
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    CHECK_TOKEN_API();
  }, []);

  const check_permissions = (module_name) => {
    var check_permissions = permissions.filter(permission => permission.name === module_name && permission.view);
    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {loader ? (
        <>

          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}
              style={{ backgroundColor: " #900000" }}
            >
              <div className="logo-user-box">
                <div className="logo-area">
                  <img className="theme-logo" src={logoTheme} />
                </div>
                {!collapsed && (
                  <>
                    <h2>Lincoln University</h2>
                    <p>Student Information System</p>
                    <div className="theme-user">
                      <div
                        className="theme-user-image"
                        style={{ backgroundImage: "url(" + user_icon + ")" }}
                      ></div>
                      <div className="theme-user-detail">
                        <p>
                          <span>{name}</span>
                          <span>{role_name ? role_name : 'Super Admin'}</span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <ul className="main-menu">
                <li className={location_pathname === '/' && 'active'} onClick={() => navigate("/")}><DashboardOutlined /> Dashboard</li>
                {all_permissions ? <>
                  <li  className={(location_pathname.startsWith('/role-list') || location_pathname === '/add-role' ||  /^\/edit-role\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/role-list")}><UserSwitchOutlined /> Roles</li>
                  <li className={(location_pathname.startsWith('/users-list') || location_pathname === '/add-user' ||  /^\/edit-user\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/users-list")}><UserOutlined /> Users</li>
                  <li className={(location_pathname.startsWith('/facultiesList') || location_pathname === '/add-faculty' ||  /^\/edit-faculty\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/facultiesList")}><SnippetsOutlined /> Faculties</li>
                  <li className={(location_pathname.startsWith('/rooms-list') || location_pathname === '/add-room' ||  /^\/edit-room\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/rooms-list")}><SnippetsOutlined /> Rooms</li>
                  <li className={(location_pathname.startsWith('/categories-list') || location_pathname === '/add-category' ||  /^\/edit-category\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/categories-list")}><SafetyCertificateOutlined /> Courses Categories</li>
                  <li className={(location_pathname.startsWith('/courses-list') || location_pathname === '/add-course' ||  /^\/edit-course\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/courses-list")}><SnippetsOutlined /> Courses</li>
                  <li className={(location_pathname.startsWith('/programmes-list') || location_pathname === '/add-programme' ||  /^\/edit-programme\/[^/]+$/.test(location_pathname) ||  /^\/section\/[^/]+$/.test(location_pathname) ||  /^\/section-view\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/programmes-list")}><SnippetsOutlined /> Programs</li>
                  <li className={(location_pathname.startsWith('/calendar-list') || location_pathname === '/add-calendar' ||  /^\/edit-Calendar\/[^/]+$/.test(location_pathname) ||  /^\/calendar-schedule\/[^/]+$/.test(location_pathname) ||  /^\/calendar-semester\/[^/]+$/.test(location_pathname) ||  /^\/add-semester\/[^/]+$/.test(location_pathname) ||  /^\/edit-semester\/[^/]+(\/[^/]+)?$/.test(location_pathname) || /^\/semester-courses\/[^/]+(\/[^/]+)?$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/calendar-list")}><CalendarOutlined /> Academic Calendar</li>
                  <li className={(location_pathname.startsWith('/registration-list') || location_pathname === '/add-registration' ||  /^\/edit-registration\/[^/]+$/.test(location_pathname) ||  /^\/edit-student-online-application\/[^/]+$/.test(location_pathname) || location_pathname === '/student-online-application-list' )&& 'active'} onClick={() => navigate("/registration-list")}><UserOutlined /> Enrollment</li>
                  <li className={(location_pathname.startsWith('/enrollments-list') || /^\/add-enrollment\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/enrollments-list")}><SnippetsOutlined /> Program Registration</li>
                  <li className={(location_pathname.startsWith('/semester-registration') || /^\/semester-registration-view\/[^/]+(\/[^/]+)?$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/semester-registration")}><UserOutlined /> Semester Registration</li>
                  <li className={/^\/health-insurance\/[^/]+(\/[^/]+)?$/.test(location_pathname) && 'active'} onClick={() => window.location = "/health-insurance"}><UserOutlined /> Student Services</li>
                  <li className={(location_pathname.startsWith('/billing') || /^\/genrate-billing\/[^/]+\/[^/]+\/[^/]+$/.test(location_pathname) || location_pathname === '/payment-plan' ) && 'active'} onClick={() => navigate("/billing")}><UserOutlined /> Semester Billing</li>
                  <li className={(location_pathname.startsWith('/students-list') ||  /^\/student-detail\/[^/]+$/.test(location_pathname) ||  /^\/student-academic-detail\/[^/]+$/.test(location_pathname) ||  /^\/students-grade-detail\/[^/]+$/.test(location_pathname) ||  /^\/edit-student-model\/[^/]+$/.test(location_pathname) ) && 'active'} onClick={() => navigate("/students-list")}><UserOutlined /> Students</li>
                  <li className={(location_pathname.startsWith('/students-requests-list') || 
                  /^\/student-repeat-request-detail\/[^/]+\/[^/]+\/[^/]+$/.test(location_pathname) || 
                  /^\/student-drop-request-detail\/[^/]+\/[^/]+\/[^/]+$/.test(location_pathname) || 
                  /^\/student-withdraw-request-detail\/[^/]+\/[^/]+$/.test(location_pathname) || 
                  /^\/student-add-request-detail\/[^/]+\/[^/]+$/.test(location_pathname)  ) && 'active'} onClick={() => navigate("/students-requests-list")}><UserOutlined /> Students Requests</li>
                  <li style={{paddingLeft:0}}>
                    {attendance_dropdown ? <>
                      <span
                       style={{paddingLeft:"19px"}}
                       className={
                        (location_pathname.startsWith('/attendence-setup') || 
                        /^\/attendance-category(\/[^/]+)?$/.test(location_pathname) || 
                        location_pathname === "attendance-category" || 
                        location_pathname.startsWith('/record-attendance-class') || 
                        /^\/record-attendance-list\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+$/.test(location_pathname) || 
                        location_pathname === '/attendance-report') ? 'active' : ''
                      } 
                         onClick={() => set_attendance_dropdown(false)}><UserOutlined /> Attendance <UpOutlined className="main-menu-arrow" /></span>
                      <ul id="attendance-dropdown">
                        <li style={{lineHeight:"40px"}} className={(location_pathname.startsWith('/attendence-setup') || 
                        /^\/attendance-category(\/[^/]+)?$/.test(location_pathname) || location_pathname == "attendance-category" )&& 'active'} onClick={() => navigate("/attendence-setup")}><UserOutlined /> Attendance Setup</li>
                        <li style={{lineHeight:"40px"}} className={(location_pathname.startsWith('/record-attendance-class') || 
                        /^\/record-attendance-list\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+$/.test(location_pathname) )&& 'active'} onClick={() => navigate("/record-attendance-class")}><UserOutlined /> Record Attendance</li>
                        <li style={{lineHeight:"40px"}} className={location_pathname === '/attendance-report' && 'active'} onClick={() => navigate("/attendance-report")}><UserOutlined />Attendance Report</li>
                      </ul>
                    </> : <span
                     style={{paddingLeft:"19px"}}
                     className={
                      (location_pathname.startsWith('/attendence-setup') || 
                      /^\/attendance-category(\/[^/]+)?$/.test(location_pathname) || 
                      location_pathname === "attendance-category" || 
                      location_pathname.startsWith('/record-attendance-class') || 
                      /^\/record-attendance-list\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+$/.test(location_pathname) || 
                      location_pathname === '/attendance-report') ? 'active' : ''
                    } 
                     onClick={() => set_attendance_dropdown(true)}><UserOutlined /> Attendance <DownOutlined className="main-menu-arrow" /></span>}
                  </li>

                  <li style={{paddingLeft:0}}>
                    {plan_dropdown ? <>
                      <span
                       style={{paddingLeft:"19px"}}
                      className={(/^\/grade-plan(\/[^/]+)?(\/[^/]+)?$/.test(location_pathname) || /^\/grading(\/[^/]+)?$/.test(location_pathname) || /^\/grading-course\/[^/]+\/[^/]+$/.test(location_pathname) ) && 'active'}
                         onClick={() => set_plan_dropdown(false)}><UserOutlined /> Grades <UpOutlined className="main-menu-arrow" /></span>
                      <ul id="attendance-dropdown">
                        <li style={{lineHeight:"40px"}} className={/^\/grade-plan(\/[^/]+)?(\/[^/]+)?$/.test(location_pathname) && 'active'} onClick={() => window.location = '/grade-plan'}><UserOutlined /> Grade Plan</li>
                        <li style={{lineHeight:"40px"}} className={(/^\/grading(\/[^/]+)?$/.test(location_pathname) || 
                      /^\/grading-course\/[^/]+\/[^/]+$/.test(location_pathname) )&& 'active'} onClick={() => navigate("/grading")}><UserOutlined /> Grades Management</li>
                      </ul>
                    </> : <span
                     style={{paddingLeft:"19px"}}
                    className={(/^\/grade-plan(\/[^/]+)?(\/[^/]+)?$/.test(location_pathname) || /^\/grading(\/[^/]+)?$/.test(location_pathname) || /^\/grading-course\/[^/]+\/[^/]+$/.test(location_pathname) ) && 'active'}
                     onClick={() => set_plan_dropdown(true)}><UserOutlined /> Grades <DownOutlined className="main-menu-arrow" /></span>}
                  </li>

                  {/* <li>
                    {report_dropdown ? <>
                      <span onClick={() => set_report_dropdown(false)}><UserOutlined /> Reports <UpOutlined className="main-menu-arrow" /></span>
                      <ul id="attendance-dropdown">
                        <li className={location_pathname === '/academic-report-section' && 'active'} onClick={() => navigate('/academic-report-section')}><UserOutlined /> Academic Report</li>
                        <li className={location_pathname === '/demographic-report-section' && 'active'} onClick={() => navigate('/demographic-report-section')}><UserOutlined /> Demographic Report</li>
                        <li className={location_pathname === '/attendance-report-section' && 'active'} onClick={() => navigate('/attendance-report-section')}><UserOutlined /> Attendance Report</li>
                      </ul>
                    </> : <span onClick={() => set_report_dropdown(true)}><UserOutlined /> Reports <DownOutlined className="main-menu-arrow" /></span>}
                  </li> */}

                  <li style={{paddingLeft:0}}>
                {report_dropdown ? (
                  <>
                  <span
                  style={{paddingLeft:"19px"}}
                 className={(location_pathname=="/advanced-academic-performance-report-section" || location_pathname === '/advanced-enrollment-registration-report-section' || location_pathname === '/advanced-enrollment-application-tracking-report-section' || location_pathname=='/advanced-financial-report-section' || location_pathname== '/advanced-student-demographics-report-section' || location_pathname=="/academic-report-section" || location_pathname=="/demographic-report-section" || location_pathname=="/attendance-report-section" ) && "active"}
                   onClick={() => set_report_dropdown(false)}>
                  <UserOutlined /> Reports <UpOutlined className="main-menu-arrow" />
                  </span>
                  <ul id="report-dropdown">

                  {/* Advanced Report Menu */}
                  <li style={{paddingLeft:"0px",lineHeight:"40px"}}>
                  <span  style={{paddingLeft:"19px"}}
                  className={(location_pathname=="/advanced-academic-performance-report-section" || location_pathname === '/advanced-enrollment-registration-report-section' || location_pathname === '/advanced-enrollment-application-tracking-report-section' || location_pathname=='/advanced-financial-report-section' || location_pathname== '/advanced-student-demographics-report-section') && "active"}
                   onClick={() => set_advanced_report_dropdown(!advanced_report_dropdown)}>
                  <UserOutlined /> Advanced Report {advanced_report_dropdown ? <UpOutlined className="main-menu-arrow" /> : <DownOutlined className="main-menu-arrow" />}
                  </span>
                  {advanced_report_dropdown && (
                  <ul>
                  <li style={{paddingLeft:"19px",lineHeight:"28px"}} className={location_pathname === '/advanced-academic-performance-report-section' && 'active'} onClick={() => navigate('/advanced-academic-performance-report-section')}>
                  <UserOutlined /> Academic Performance Reports
                  </li>
                  <li style={{paddingLeft:"19px",lineHeight:"28px"}} className={location_pathname === '/advanced-enrollment-registration-report-section' && 'active'} onClick={() => navigate('/advanced-enrollment-registration-report-section')}>
                  <UserOutlined /> Enrollment & Registration Reports
                  </li>
                  <li style={{paddingLeft:"19px",lineHeight:"28px"}} className={location_pathname === '/advanced-enrollment-application-tracking-report-section' && 'active'} onClick={() => navigate('/advanced-enrollment-application-tracking-report-section')}>
                  <UserOutlined /> Enrollment Application Tracking Reports
                  </li>
                  <li style={{paddingLeft:"19px",lineHeight:"40px"}} className={location_pathname === '/advanced-financial-report-section' && 'active'} onClick={() => navigate('/advanced-financial-report-section')}>
                  <UserOutlined /> Financial Reports
                  </li>
                  <li style={{paddingLeft:"19px",lineHeight:"28px"}} className={location_pathname === '/advanced-student-demographics-report-section' && 'active'} onClick={() => navigate('/advanced-student-demographics-report-section')}>
                  <UserOutlined /> Student Demographics Reports
                  </li>
                  </ul>
                  )}
                  </li>

                  {/* Basic Report Menu */}
                  <li style={{paddingLeft:"0px",lineHeight:"40px"}}>
                  <span
                   style={{paddingLeft:"19px"}}
                  className={(location_pathname=="/academic-report-section" || location_pathname=="/demographic-report-section" || location_pathname=="/attendance-report-section" ) && "active"}
                   onClick={() => set_basic_report_dropdown(!basic_report_dropdown)}>
                  <UserOutlined /> Basic Report {basic_report_dropdown ? <UpOutlined className="main-menu-arrow" /> : <DownOutlined className="main-menu-arrow" />}
                  </span>
                  {basic_report_dropdown && (
                  <ul>
                  <li style={{paddingLeft:"19px",lineHeight:"40px"}} className={location_pathname === '/academic-report-section' && 'active'} onClick={() => navigate('/academic-report-section')}><UserOutlined /> Academic Report</li>
                  <li style={{paddingLeft:"19px",lineHeight:"40px"}} className={location_pathname === '/demographic-report-section' && 'active'} onClick={() => navigate('/demographic-report-section')}><UserOutlined /> Demographic Report</li>
                  <li style={{paddingLeft:"19px",lineHeight:"40px"}} className={location_pathname === '/attendance-report-section' && 'active'} onClick={() => navigate('/attendance-report-section')}><UserOutlined /> Attendance Report</li>
                  </ul>
                  )}
                  </li>
                  </ul>
                  </>
                  ) : (
                  <span
                  style={{paddingLeft:"19px"}}
                  className={(location_pathname=="/advanced-academic-performance-report-section" || location_pathname === '/advanced-enrollment-registration-report-section' || location_pathname === '/advanced-enrollment-application-tracking-report-section' || location_pathname=='/advanced-financial-report-section' || location_pathname== '/advanced-student-demographics-report-section' || location_pathname=="/academic-report-section" || location_pathname=="/demographic-report-section" || location_pathname=="/attendance-report-section" ) && "active"}
                   onClick={() => set_report_dropdown(true)}>
                  <UserOutlined /> Reports <DownOutlined className="main-menu-arrow" />
                  </span>
                  )}
                  </li>

                  <li onClick={() => {
                    localStorage.clear();
                    //window.location = 'https://lusis.lincolnuca.edu/';
                    window.location = 'https://dev-students.icore.uk/';
                  }}><LoginOutlined /> Logout</li>
                </> : <>
                  {check_permissions('roles') && <li className={location_pathname === '/role-list' && 'active'} onClick={() => navigate("/role-list")}><UserSwitchOutlined /> Roles</li>}
                  {check_permissions('users') && <li className={location_pathname === '/users-list' && 'active'} onClick={() => navigate("/users-list")}><UserOutlined /> Users</li>}
                  {check_permissions('faculties') && <li className={location_pathname === '/facultiesList' && 'active'} onClick={() => navigate("/facultiesList")}><SnippetsOutlined /> Faculties</li>}
                  {check_permissions('class_rooms') && <li className={location_pathname === '/rooms-list' && 'active'} onClick={() => navigate("/rooms-list")}><SnippetsOutlined /> Rooms</li>}
                  {check_permissions('categories') && <li className={location_pathname === '/categories-list' && 'active'} onClick={() => navigate("/categories-list")}><SafetyCertificateOutlined /> Courses Categories</li>}
                  {check_permissions('courses') && <li className={location_pathname === '/courses-list' && 'active'} onClick={() => navigate("/courses-list")}><SnippetsOutlined /> Courses</li>}
                  {check_permissions('sis_programmes') && <li className={location_pathname === '/programmes-list' && 'active'} onClick={() => navigate("/programmes-list")}><SnippetsOutlined /> Programs</li>}
                  {check_permissions('academic_calendars') && <li className={location_pathname === '/calendar-list' && 'active'} onClick={() => navigate("/calendar-list")}><CalendarOutlined /> Academic Calendar</li>}
                  {check_permissions('enrollments') && <li className={location_pathname === '/registration-list' && 'active'} onClick={() => navigate("/registration-list")}><UserOutlined /> Enrollment</li>}
                  {check_permissions('students_programmes_registrations') && <li className={location_pathname === '/enrollments-list' && 'active'} onClick={() => navigate("/enrollments-list")}><SnippetsOutlined /> Program Registration</li>}
                  {check_permissions('semester_registrations') && <li className={location_pathname === '/semester-registration' && 'active'} onClick={() => navigate("/semester-registration")}><UserOutlined /> Semester Registration</li>}
                  {check_permissions('student_semester_health_insurance') && <li className={location_pathname === '/health-insurance' && 'active'} onClick={() => window.location = "/health-insurance"}><UserOutlined /> Student Services</li>}
                  {check_permissions('billings') && <li className={location_pathname === '/billing' && 'active'} onClick={() => navigate("/billing")}><UserOutlined /> Semester Billing</li>}

                  {check_permissions('enrollments') && <li className={location_pathname === '/students-list' && 'active'} onClick={() => navigate("/students-list")}><UserOutlined /> Students</li>}
                  {check_permissions('enrollments') && <li className={location_pathname === '/students-requests-list' && 'active'} onClick={() => navigate("/students-requests-list")}><UserOutlined /> Students Requests</li>}
                  {(check_permissions('attendances') || check_permissions('attendance_categories')) &&
                    <li>
                      {attendance_dropdown ? <>
                        <span onClick={() => set_attendance_dropdown(false)}><UserOutlined /> Attendance <UpOutlined className="main-menu-arrow" /></span>
                        <ul id="attendance-dropdown">
                          {check_permissions('attendance_categories') && <li className={location_pathname === '/attendence-setup' && 'active'} onClick={() => navigate("/attendence-setup")}><UserOutlined /> Attendance Setup</li>}
                          {check_permissions('attendances') && <li className={location_pathname === '/record-attendance-class' && 'active'} onClick={() => navigate("/record-attendance-class")}><UserOutlined /> Record Attendance</li>}
                          {check_permissions('attendances') && <li className={location_pathname === '/attendance-report' && 'active'} onClick={() => navigate("/attendance-report")}><UserOutlined /> Attendance Reports</li>}
                        </ul>
                      </> : <span onClick={() => set_attendance_dropdown(true)}><UserOutlined /> Attendance <DownOutlined className="main-menu-arrow" /></span>}
                    </li>}

                  {check_permissions('students_grades') &&
                    <li>
                      {plan_dropdown ? <>
                        <span onClick={() => set_plan_dropdown(false)}><UserOutlined /> Grades <UpOutlined className="main-menu-arrow" /></span>
                        <ul id="attendance-dropdown">
                          {check_permissions('grade_plan') && <li className={location_pathname === '/grade-plan' && 'active'} onClick={() => window.location = '/grade-plan'}><UserOutlined /> Grade Plan</li>}
                          {check_permissions('students_grades') && <li className={location_pathname === '/grading' && 'active'} onClick={() => navigate("/grading")}><UserOutlined /> Grades Management</li>}
                        </ul>
                      </> : <span onClick={() => set_plan_dropdown(true)}><UserOutlined /> Grades <DownOutlined className="main-menu-arrow" /></span>}
                    </li>
                  }

                  {/* {check_permissions('') &&
                    <li>
                      {report_dropdown ? <>
                        <span onClick={() => set_report_dropdown(false)}><UserOutlined /> Reports <UpOutlined className="main-menu-arrow" /></span>
                        <ul id="attendance-dropdown">
                          <li className={location_pathname === '/academic-report-section' && 'active'} onClick={() => navigate('/academic-report-section')}><UserOutlined /> Academic Report</li>
                          <li className={location_pathname === '/demographic-report-section' && 'active'} onClick={() => navigate('/demographic-report-section')}><UserOutlined /> Demographic Report</li>
                          <li className={location_pathname === '/attendance-report-section' && 'active'} onClick={() => navigate('/attendance-report-section')}><UserOutlined /> Attendance Report</li>
                        </ul>
                      </> : <span onClick={() => set_report_dropdown(true)}><UserOutlined /> Reports <DownOutlined className="main-menu-arrow" /></span>}
                    </li>
                  } */}

                {check_permissions('') &&
                <li>
                {report_dropdown ? (
                  <>
                  <span onClick={() => set_report_dropdown(false)}>
                  <UserOutlined /> Reports <UpOutlined className="main-menu-arrow" />
                  </span>
                  <ul id="report-dropdown">

                  {/* Advanced Report Menu */}
                  <li style={{paddingLeft:"5px",lineHeight:"40px"}}>
                  <span onClick={() => set_advanced_report_dropdown(!advanced_report_dropdown)}>
                  <UserOutlined /> Advanced Report {advanced_report_dropdown ? <UpOutlined className="main-menu-arrow" /> : <DownOutlined className="main-menu-arrow" />}
                  </span>
                  {advanced_report_dropdown && (
                  <ul>
                  <li style={{paddingLeft:"8px",lineHeight:"28px"}} className={location_pathname === '/advanced-academic-report-section' && 'active'} onClick={() => navigate('/advanced-academic-report-section')}>
                  <UserOutlined /> Academic Performance Report
                  </li>
                  <li style={{paddingLeft:"8px",lineHeight:"28px"}} className={location_pathname === '/advanced-enrollment-registration-report-section' && 'active'} onClick={() => navigate('/advanced-enrollment-registration-report-section')}>
                  <UserOutlined /> Enrollment and Registartion Report
                  </li>
                  <li style={{paddingLeft:"8px",lineHeight:"28px"}} className={location_pathname === '/advanced-enrollment-application-Tracking-report-section' && 'active'} onClick={() => navigate('/advanced-enrollment-application-Tracking-report-section')}>
                  <UserOutlined /> Enrollment Application Tracking Reports
                  </li>
                  <li style={{paddingLeft:"8px",lineHeight:"28px"}} className={location_pathname === '/advanced-financial-report-section' && 'active'} onClick={() => navigate('/advanced-financial-report-section')}>
                  <UserOutlined /> Financial Reports
                  </li>
                  <li style={{paddingLeft:"8px",lineHeight:"28px"}} className={location_pathname === '/advanced-student-demographics-report-section' && 'active'} onClick={() => navigate('/advanced-student-demographics-report-section')}>
                  <UserOutlined /> Student Demographics Reports
                  </li>
                  </ul>
                  )}
                  </li>

                  {/* Basic Report Menu */}
                  <li style={{paddingLeft:"5px",lineHeight:"40px"}}>
                  <span onClick={() => set_basic_report_dropdown(!basic_report_dropdown)}>
                  <UserOutlined /> Basic Report {basic_report_dropdown ? <UpOutlined className="main-menu-arrow" /> : <DownOutlined className="main-menu-arrow" />}
                  </span>
                  {basic_report_dropdown && (
                  <ul>
                  <li style={{paddingLeft:"8px",lineHeight:"40px"}} className={location_pathname === '/academic-report-section' && 'active'} onClick={() => navigate('/academic-report-section')}><UserOutlined /> Academic Report</li>
                  <li style={{paddingLeft:"8px",lineHeight:"40px"}} className={location_pathname === '/demographic-report-section' && 'active'} onClick={() => navigate('/demographic-report-section')}><UserOutlined /> Demographic Report</li>
                  <li style={{paddingLeft:"8px",lineHeight:"40px"}} className={location_pathname === '/attendance-report-section' && 'active'} onClick={() => navigate('/attendance-report-section')}><UserOutlined /> Attendance Report</li>
                  </ul>
                  )}
                  </li>
                  </ul>
                  </>
                  ) : (
                  <span onClick={() => set_report_dropdown(true)}>
                  <UserOutlined /> Reports <DownOutlined className="main-menu-arrow" />
                  </span>
                  )}
                  </li>
                  }

                  <li onClick={() => {
                    localStorage.clear();
                    // window.location = 'https://lusis.lincolnuca.edu/';
                    window.location = 'https://dev-students.icore.uk/';
                  }}><LoginOutlined /> Logout</li>
                </>}
              </ul>

            </Sider>
            <Layout>
              <Header style={{ padding: 0, background: colorBgContainer }}>
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
              </Header>
              <Content
                style={{
                  margin: '24px 16px',
                  padding: 24,
                  minHeight: window.innerHeight,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </>

      )}
    </>
  );
};

export default SisLayout;
