import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import "./attendance.css";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import AttendanceSetupRow from "./attendanceSetupRow";
import { LIST_ATTENDANCE_CATEGORY } from "../../apis/apis";
import SisLoader from "../../widgets/loader";

function Attendancesetup() {
  const navigate = useNavigate();
  const [show_container, set_show_container] = useState(false);
  const [loader, set_loader] = useState(true);
  const [list, set_list] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
  }
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const RESPONSE = await LIST_ATTENDANCE_CATEGORY(FORM_DATA);
    if (RESPONSE?.data?.status) {
      set_list(RESPONSE?.data?.categories)
      set_loader(false)
    } else {
      set_loader(false)
    }
  }
  useEffect(() => {
    LIST_API()
  }, [])
  return (
    <>
      {loader ? <SisLoader /> : <>
      {check_permissions('attendance_categories', 'create') &&
        <Button type="primary" onClick={() => navigate("/attendance-category")}>Add Category</Button>}<br></br><br></br>
        {list.length > 0 ? <>
          {list.map((item, index) => (
            <>{index == 0 ? <AttendanceSetupRow item={item} open={true} /> : <AttendanceSetupRow item={item} open={false} />}</>
          ))}

        </> : <>
          <p style={{ margin: "50px 0", textAlign: "center", color: "red", fontSize: "24px" }}>Attendance Categories data empty</p>
        </>}
      </>}


    </>
  );
}

export default Attendancesetup;
