import React, { useEffect, useState } from "react";
import "./grading.css";
import { useNavigate, useParams } from "react-router-dom";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { ACADEMIC_SEMESTER, FACULTIES_LIST, SEMESTER_WITH_REGISTERED_COURSE, sisDateFormat } from "../../apis/apis";
import { Button, Input, Select, Table } from "antd";
// import grading from "./Gradingobject"

function Grading() {
  const navigate = useNavigate();
  const { semester_id } = useParams();
  const [page_loader, set_page_loader] = useState(false);
  const [history_semester_id, set_history_semester_id] = useState('');
  // Permissions state manage 
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const [facultytablelist, setFacultyTableList] = useState([]);
  const [faculty_id, set_faculty_id] = useState('');
  const [grade_plan, set_grade_plan] = useState('');
  const [grade_plan_active, set_grade_plan_active] = useState(0);



  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
    }
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const [semesters_list, set_semesters_list] = useState([]);
  const [courses_list, set_courses_list] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const ACADEMIC_SEMESTER_LIST = async () => {
    const FORM_DATA = new FormData();
    const API_RESPONSE = await ACADEMIC_SEMESTER(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_semesters_list(API_RESPONSE?.data?.academic_semester);
      if (semester_id) {
        set_history_semester_id(atob(semester_id))
        SEMESTER_WITH_REGISTERED_COURSE_LIST(atob(semester_id), '')
      } else {
        if (API_RESPONSE?.data?.academic_semester?.length > 0) {
          set_history_semester_id(API_RESPONSE?.data?.academic_semester[0].id)
          SEMESTER_WITH_REGISTERED_COURSE_LIST(API_RESPONSE?.data?.academic_semester[0].id, '')
        }
      }
    } else {
      set_page_loader(false);
    }
  };

  const SEMESTER_WITH_REGISTERED_COURSE_LIST = async (semester_id, faculty_id) => {
    const FORM_DATA = new FormData();
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append('semester_id', semester_id);
    FORM_DATA.append('faculty_id', faculty_id);
    const API_RESPONSE = await SEMESTER_WITH_REGISTERED_COURSE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_courses_list(API_RESPONSE?.data?.courses);
      set_grade_plan(API_RESPONSE?.data?.grade_plan);
      set_grade_plan_active(API_RESPONSE?.data?.grade_plan_active);
      setFilteredData(API_RESPONSE?.data?.courses);
      set_page_loader(false);
    } else {
      set_page_loader(false);
    }
  };
  const LIST_API = async () => {
    const FACULTIES_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', FACULTIES_DATA.token);
    const API_RESPONSE = await FACULTIES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      setFacultyTableList(API_RESPONSE?.data?.faculty);

    } else {

    }
  };

  useEffect(() => {
    ACADEMIC_SEMESTER_LIST();
    LIST_API();
  }, [semester_id]);


  const columns = [
    {
      title: "Code",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.course_code}</span></>;
      },
    },
    {
      title: "Course",
      render: (text, record) => {
        return <>
        <span style={{ fontSize: "10px", }}>{record.course_title}</span>
        </>;
      },
    },
   
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.faculties_name}</span></>;
      },
    },
    {
      title: "# of students",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.number_of_students}</span></>;
      },
    },
    {
      title: "Not Ready",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.not_ready_for_review}</span></>;
      },
    },
    {
      title: "Reviewed",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.mark_as_reviewed}</span></>;
      },
    },
    {
      title: "Not Reviewed",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.ready_for_review}</span></>;
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        return <>{check_permissions('students_grades','view') && 
          <Button type="primary" size="small" onClick={() => navigate('/grading-course/' + btoa(parseInt(record.semester_id)) + '/' + btoa(record.course_id))}>View</Button>
      }</>;
      },
    },


  ];


  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                <span
                  style={{
                    width: "110px",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    margin: "3px 0px"
                  }}
                >
                  {item?.value?.slice(0, 2).toUpperCase()}{" "}
                  {item?.start_time} - {item?.end_time}
                </span>
              </>
            )}
          </>
        ))}
      </>
    );
  };


  //  Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    var table_data = courses_list;
    const filtered = table_data.filter(item =>
      item.course_title.toLowerCase().includes(query.toLowerCase()) || item.course_code.toLowerCase().includes(query.toLowerCase())

    );
    if (query === '') {
      setFilteredData(courses_list);
    } else {
      setFilteredData(filtered);
    }
  };


  return (
    <>
      <div>
        <div className="theme-content-head">
          <div className="theme-content-left-head">
            <h3>Grading Courses</h3>
          </div>
          <div className="theme-content-right-head"></div>
        </div>
        <div className="sis-tab-menu">
          {semesters_list?.length > 0 &&
            semesters_list.map((item, index) => (
              <>
                {semester_id ? (
                  <>
                    {parseInt(atob(semester_id)) === item.id ? (
                      <>
                        <span className="active" onClick={() => navigate('/grading/' + btoa(item.id))}>{item.semester_title}</span>
                      </>
                    ) : (
                      <>
                        <span onClick={() => navigate('/grading/' + btoa(item.id))}>{item.semester_title}</span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {index === 0 ? (
                      <>
                        <span className="active" onClick={() => navigate('/grading/' + btoa(item.id))}>{item.semester_title}</span>
                      </>
                    ) : (
                      <>
                        <span onClick={() => navigate('/grading/' + btoa(item.id))}>{item.semester_title}</span>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          {/*                     
                    <span>Semesters</span>
                    <span>Schedules</span> */}
        </div>
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            
            
            <div className='row'>
              <div className='col-2'>
                <label style={{ fontWeight: "bold", position: "relative", top: "3px", }}>Search Course/course code</label>
              </div>
              <div className='col-2'>
                <Input
                  type="text"
                  style={{ height: "32px" }}
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />

              </div>
              {(check_permissions('students_grades','update') || check_permissions('students_grades','create') )&& 
              <div className='col-3'>
                <Select onChange={(value) => SEMESTER_WITH_REGISTERED_COURSE_LIST(history_semester_id, value)} style={{ width: "100%", marginBottom: "15px" }} placeholder="--Select Faculty---">
                  {facultytablelist?.length > 0 && <>
                    <><Select.Option value={''}>All</Select.Option></>
                    {facultytablelist?.map(itemv => (
                      <><Select.Option value={itemv.id}>{itemv.name}</Select.Option></>
                    ))}
                  </>}
                </Select>
              </div>}

            </div>
            {grade_plan && <>
              <h4 style={{ marginBottom:"10px" }}>Grading Plan</h4>
              <div className="row grade_plan_row">
                <div className="col-2">
                  <label>Due date</label>
                  <p>{sisDateFormat(grade_plan?.dua_date)}</p>
                </div>
                <div className="col-2">
                  <label>Grading start date</label>
                  <p>{sisDateFormat(grade_plan?.start_grading_date)}</p>
                </div>
                <div className="col-2">
                  <label>Grading end date</label>
                  <p>{sisDateFormat(grade_plan?.end_grading_date)}</p>
                </div>
                <div className="col-2">
                  <label>Review start date</label>
                  <p>{sisDateFormat(grade_plan?.review_start_date)}</p>
                </div>
                <div className="col-2">
                  <label>Review end date</label>
                  <p>{sisDateFormat(grade_plan?.review_end_date)}</p>
                </div>
                <div className="col-2">
                  <label>Status</label>
                  <p>{grade_plan_active > 0 ? <span style={{ color:"green" }}>Active</span> : <span style={{ color:"red" }}>Inactive</span>}</p>
                </div>
              </div> </>}
            <div className="sis-tab-content">
              {grade_plan ? <>
                <Table dataSource={filteredData} columns={columns} />
              </> : <>
                <p style={{ color: "red", padding: "10px", textAlign: "center" }}>There is no grading plan for this semester.</p>
              </>}

            </div>
          </>
        )}
      </div>
    </>
  );


}

export default Grading;
